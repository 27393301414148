<template>
    <div
        id="toolTipContainer"
        :class="['row', 'add-rule-form', {'in-merge-view': inMergeView, 'read-only': readOnly, 'editing': !readOnly}]"
    >
        <nav
            v-if="!inMergeView"
            class="navbar col-12 sticky-top edit-navbar"
        >
            <ul class="nav nav-pills">
                <li class="nav-item">
                    <a
                        v-scroll-to="{el: '#ruleDescription', duration: 500, offset: 80}"
                        href="#"
                        class="nav-link"
                        @click.prevent="$ga.event('Add Edit Form', 'Scroll To Description', 'action', $event);"
                    >
                        Description
                    </a>
                </li>

                <li class="nav-item">
                    <a
                        v-scroll-to="{el: '#ruleConditions', duration: 500, offset: -180}"
                        href="#"
                        class="nav-link"
                        @click.prevent="$ga.event('Add Edit Form', 'Scroll To Conditions', 'action', $event);"
                    >
                        Rule Conditions
                    </a>
                </li>

                <li class="nav-item">
                    <a
                        v-scroll-to="{el: '#ruleActions', duration: 500, offset: 0}"
                        href="#"
                        class="nav-link"
                        @click.prevent="$ga.event('Add Edit Form', 'Scroll To Actions', 'action', $event);"
                    >
                        Actions
                    </a>
                </li>
            </ul>

            <ul class="nav nav-pills ml-auto">
                <li class="nav-item">
                    <RouterLink
                        aria-label="Back to All Rules"
                        data-cy="backToAllRules"
                        class="btn btn-primary"
                        :to="{name: $route.params.ruleId ? 'admin.rules.options' : 'admin.rules.list', params: {policySystemId: currentPolicySystem.id, ...$route.params}, query: {...$route.query}}"
                        @click.prevent="finish"
                    >
                        <FontAwesomeIcon
                            :icon="['far', 'newspaper']"
                        />

                        Back to All Rules
                    </RouterLink>
                </li>

                <li
                    v-if="!readOnly"
                    class="nav-item ml-2"
                >
                    <button
                        data-cy="ruleBuilderSave"
                        :class="['btn', 'btn-primary', {'disabled': isSaving}]"
                        @click="save"
                    >
                        <FontAwesomeIcon
                            v-if="isSaving"
                            icon="spinner"
                            class="loading-spinner"
                            pulse
                        />

                        <FontAwesomeIcon
                            v-else
                            icon="save"
                        />

                        Save
                    </button>
                </li>
                <li
                    v-else
                    class="nav-item ml-2"
                >
                    <button
                        data-cy="ruleBuilderEvaluate"
                        :class="['btn', 'btn-primary']"
                        @click="showTestModal"
                    >
                        <FontAwesomeIcon icon="square-terminal" />

                        Evaluate Rule
                    </button>
                </li>
            </ul>
        </nav>

        <ValidationObserver
            ref="validationObserver"
            tag="div"
            class="col-xs-12 col-md-12 imt-ui-select-group"
        >
            <div
                v-if="title"
                id="ruleDescription"
                class="form-group"
            >
                <label class="form-control-label">
                    Name
                </label>

                <FontAwesomeIcon
                    id="AddEditName"
                    icon="info-circle"
                    class="fa ml-1 kc-help-wording"
                />
                <BPopover
                    custom-class="kc-help-wording"
                    target="AddEditName"
                    title="Name"
                    triggers="hover"
                    :content="helpWording.rules.adding.title"
                />
                <input
                    class="form-control"
                    disabled="disabled"
                    :value="title"
                >
            </div>

            <template v-if="!versionId">
                <IMTUISelect
                    v-if="!inMergeView"
                    :id="title ? '' : 'ruleDescription'"
                    ref="ruleType"
                    v-model="ruleType"
                    data-cy="ruleType"
                    label="Rule Type"
                    :options="ruleTypes"
                    :read-only="readOnly"
                    @item-selected="resetPageType"
                >
                    <span slot="help-wording">
                        <FontAwesomeIcon
                            id="AddEditRuleType"
                            icon="info-circle"
                            class="fa ml-1 kc-help-wording"
                        />

                        <BPopover
                            custom-class="kc-help-wording"
                            target="AddEditRuleType"
                            title="Rule Type"
                            triggers="hover"
                            :content="helpWording.rules.adding.ruleType"
                        />
                    </span>
                </IMTUISelect>

                <template v-if="showPageFields && !inMergeView">
                    <template v-if="pageType">
                        <ValidationProvider
                            v-slot="{errors}"
                            tag="div"
                            rules="required"
                            name="pageId"
                        >
                            <PopoverSelect
                                id="addEditFormPageSelect"
                                ref="pageSelectPopover"
                                v-model="pageId"
                                data-vv-as="Page"
                                data-cy="pageSelect"
                                :label="pageSelectLabel"
                                name="pageId"
                                :options="pageOptions"
                                :placeholder="pageSelectPlaceholder"
                                :read-only="readOnly"
                            />

                            <ValidationError
                                custom-class="page-id"
                                :errors="errors"
                                target="popoverLabeladdEditFormPageSelect"
                                tool-tip-container="toolTipContainer"
                                triggers=""
                            />
                        </ValidationProvider>
                    </template>
                </template>

                <div
                    v-if="!versionId || effectiveDate"
                    class="form-group"
                >
                    <label class="form-control-label">
                        Policy Effective Date (optional)
                    </label>

                    <FontAwesomeIcon
                        id="AddEditPolicyEffectiveDate"
                        icon="info-circle"
                        class="fa ml-1 kc-help-wording"
                    />

                    <BPopover
                        custom-class="kc-help-wording"
                        target="AddEditPolicyEffectiveDate"
                        title="Policy Effective Date"
                        triggers="hover"
                        :content="helpWording.rules.adding.policyEffectiveDate"
                    />

                    <div class="input-group">
                        <DatePicker
                            id="addEditPolicyEffectiveDate"
                            v-model="effectiveDate"
                            data-cy="addEditPolicyEffectiveDate"
                            :disabled="versionId || inMergeView"
                        />
                    </div>
                </div>
            </template>

            <ValidationProvider
                id="transactionTypeContainer"
                v-slot="{errors}"
                tag="div"
                class="highlight-container my-4"
                rules="required"
                name="transactionType"
            >
                <IMTUISelect
                    ref="transactionType"
                    v-model="transactionType"
                    data-cy="transactionType"
                    label="Transaction Type"
                    :multiple="true"
                    name="transactionType"
                    :options="transactionTypes"
                    :read-only="readOnly"
                >
                    <span slot="help-wording">
                        <FontAwesomeIcon
                            id="AddEditPolicyTransactionType"
                            icon="info-circle"
                            class="fa ml-1 kc-help-wording"
                        />

                        <BPopover
                            custom-class="kc-help-wording"
                            target="AddEditPolicyTransactionType"
                            title="Transaction Type"
                            triggers="hover"
                            :content="helpWording.rules.adding.transactionType"
                        />
                    </span>
                </IMTUISelect>

                <ValidationError
                    custom-class="transaction-type"
                    :errors="errors"
                    field-name="transactionType"
                    target="AddEditPolicyTransactionType"
                    tool-tip-container="toolTipContainer"
                    triggers=""
                />
            </ValidationProvider>

            <div class="form-group">
                <VRelatedItemView
                    v-if="selectedVersion.id"
                    :item-id="selectedRule.id"
                    item-type="rule"
                    :item-version-id="selectedVersion.id"
                />

                <VRelatedItemSelect
                    v-else-if="!inMergeView"
                    @selection="taskId = $event.taskId"
                />
            </div>

            <div
                id="description"
                class="form-group highlight-container"
            >
                <ValidationProvider
                    v-slot="{errors}"
                    tag="div"
                    rules="required|max:200"
                    name="description"
                >
                    <label
                        id="descriptionLabel"
                        class="form-control-label"
                    >
                        Description
                    </label>

                    <FontAwesomeIcon
                        id="AddEditDescriptionHelp"
                        icon="info-circle"
                        class="fa ml-1 kc-help-wording"
                    />

                    <BPopover
                        custom-class="kc-help-wording"
                        target="AddEditDescriptionHelp"
                        title="Description"
                        triggers="hover"
                        :content="helpWording.rules.adding.description"
                    />

                    <textarea
                        ref="description"
                        v-model="description"
                        data-cy="addEditDescription"
                        class="form-control"
                        data-vv-as="Description"
                        :disabled="readOnly"
                        name="description"
                        placeholder="type a description of the rule..."
                    />

                    <ValidationError
                        custom-class="description"
                        :errors="errors"
                        target="descriptionLabel"
                        tool-tip-container="toolTipContainer"
                        triggers=""
                    />
                </ValidationProvider>
            </div>

            <div
                id="internalNotes"
                class="form-group highlight-container"
            >
                <div>
                    <label class="form-control-label">
                        Internal Notes (optional)
                    </label>

                    <FontAwesomeIcon
                        id="AddEditInternalNotes"
                        icon="info-circle"
                        class="fa ml-1 kc-help-wording"
                    />

                    <BPopover
                        custom-class="kc-help-wording"
                        target="AddEditInternalNotes"
                        title="Internal Notes"
                        triggers="hover"
                        :content="helpWording.rules.adding.internalNotes"
                    />

                    <textarea
                        v-model="internalNotes"
                        data-cy="addEditInternalNotes"
                        class="form-control"
                        :disabled="readOnly"
                        placeholder="type some optional internal notes..."
                    />
                </div>
            </div>

            <div
                id="helpWording"
                class="form-group highlight-container"
            >
                <label class="form-control-label">
                    Help Wording (optional)
                </label>

                <FontAwesomeIcon
                    id="addEditHelpWordingIcon"
                    icon="info-circle"
                    class="fa ml-1 kc-help-wording"
                />

                <BPopover
                    custom-class="kc-help-wording"
                    target="addEditHelpWordingIcon"
                    title="Help Wording"
                    triggers="hover"
                    :content="helpWording.rules.adding.helpWordingText"
                />

                <textarea
                    id="addEditHelpWording"
                    v-model="helpWordingText"
                    data-cy="addEditHelpWording"
                    class="form-control"
                    maxlength="50"
                    :disabled="readOnly"
                    placeholder="type some optional help wording..."
                />
            </div>

            <div
                id="ruleConditions"
                class="form-group"
            >
                <label class="form-control-label">
                    Conditions
                </label>

                <FontAwesomeIcon
                    id="AddEditConditions"
                    icon="info-circle"
                    class="fa ml-1 kc-help-wording"
                />

                <BPopover
                    custom-class="kc-help-wording"
                    target="AddEditConditions"
                    title="Conditions"
                    triggers="hover"
                    :content="helpWording.rules.adding.ruleConditions"
                />

                <BRExpressionBuilder
                    ref="expressionBuilder"
                    :is-new-rule="!Object.keys(selectedVersion).length"
                    :read-only="readOnly"
                    :condition="condition"
                    @toggle-change="$emit('toggle-change', $event)"
                />
            </div>

            <ValidationProvider
                v-if="!isPageDisplayOrCleanUp"
                v-slot="{errors}"
                tag="div"
                class="highlight-container"
                rules="required"
                name="canAccommodate"
            >
                <IMTUISelect
                    v-model="canAccommodate"
                    label="Can Be Accommodated"
                    name="canAccommodate"
                    data-cy="canBeAccommodated"
                    :options="yesNoOptions"
                    :read-only="readOnly"
                >
                    <span slot="help-wording">
                        <FontAwesomeIcon
                            id="AddEditCanAccommodate"
                            icon="info-circle"
                            class="fa ml-1 kc-help-wording"
                        />

                        <BPopover
                            custom-class="kc-help-wording"
                            target="AddEditCanAccommodate"
                            title="Can Be Accommodated"
                            triggers="hover"
                            :content="helpWording.rules.adding.canAccommodate"
                        />
                    </span>
                </IMTUISelect>

                <ValidationError
                    :errors="errors"
                    field-name="canAccommodate"
                    target="AddEditCanAccommodate"
                    tool-tip-container="toolTipContainer"
                    triggers=""
                />
            </ValidationProvider>

            <ValidationProvider
                v-if="!isPageDisplayOrCleanUp"
                v-slot="{errors}"
                tag="div"
                class="highlight-container"
                rules="required"
                name="underwritingFix"
            >
                <IMTUISelect
                    v-model="underwritingFix"
                    label="Requires Underwriting Fix"
                    name="underwritingFix"
                    data-cy="requiresUnderwritingFix"
                    :options="yesNoOptions"
                    :read-only="readOnly"
                >
                    <span slot="help-wording">
                        <FontAwesomeIcon
                            id="AddEditUnderwritingFix"
                            icon="info-circle"
                            class="fa ml-1 kc-help-wording"
                        />

                        <BPopover
                            custom-class="kc-help-wording"
                            target="AddEditUnderwritingFix"
                            title="Requires Underwriting Fix"
                            triggers="hover"
                            :content="helpWording.rules.adding.underwritingFix"
                        />
                    </span>
                </IMTUISelect>

                <ValidationError
                    :errors="errors"
                    field-name="UnderwritingFix"
                    target="AddEditUnderwritingFix"
                    tool-tip-container="toolTipContainer"
                    triggers=""
                />
            </ValidationProvider>


            <div class="form-group d-flex mt-3">
                <div class="mt-auto mb-auto mr-1">
                    <span>When</span>
                </div>

                <div
                    id="condition"
                    class="highlight-container d-inline-block"
                >
                    <div>
                        <Multiselect
                            v-model="condition"
                            :allow-empty="false"
                            class="rule-condition-options"
                            deselect-label=""
                            :disabled="readOnly"
                            label="label"
                            :options="conditionOptions"
                            select-label=""
                            style="max-width: 12em"
                            track-by="value"
                        />
                    </div>
                </div>

                <template v-if="!showPageFields">
                    <div class="mt-auto mb-auto ml-1 mr-1">
                        <span>at</span>
                    </div>

                    <div
                        id="category"
                        class="highlight-container d-inline-block"
                    >
                        <div>
                            <Multiselect
                                v-model="category"
                                :allow-empty="false"
                                class="rule-conditions"
                                deselect-label=""
                                :disabled="readOnly"
                                label="label"
                                :options="categoryOptions"
                                select-label=""
                                style="width: 20em"
                                track-by="value"
                            />
                        </div>
                    </div>
                </template>
            </div>

            <div
                id="ruleActions"
                class="form-group"
            >
                <label class="form-control-label">
                    Actions
                </label>

                <FontAwesomeIcon
                    id="AddEditActions"
                    icon="info-circle"
                    class="fa ml-1 kc-help-wording"
                />

                <BPopover
                    custom-class="kc-help-wording"
                    target="AddEditActions"
                    title="Actions"
                    triggers="hover"
                    :content="helpWording.rules.adding.actions"
                />

                <div
                    v-for="(action, index) in orderedActions"
                    :key="action.id"
                    class="highlight-container mb-1"
                >
                    <BRAction
                        ref="actions"
                        :initial-action="action"
                        :read-only="readOnly"
                        :show-add-button="index === actions.length - 1"
                        :show-remove-button="actions.length > 1"
                        @add-action="addAction"
                        @remove-action="removeAction(action)"
                        @toggle-change="$emit('toggle-change', $event)"
                    />
                </div>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    import IMTUISelect from '@imt/vue-imt-ui-select/src/components/IMTUISelect.vue';
    import DatePicker from '@imt/vue-kit-car/src/components/DatePicker.vue';
    import PopoverSelect from '@imt/vue-popover-select/src/components/PopoverSelect.vue';
    import ValidationError from '@imt/vue-toolbox/src/components/ValidationError.vue';
    import authMixin from '@imt/vue-toolbox/src/mixins/auth';
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import utils from '@imt/vue-toolbox/src/utils';
    import VRelatedItemSelect from '@imt/vue-versioning/src/components/RelatedItemSelect.vue';
    import VRelatedItemView from '@imt/vue-versioning/src/components/RelatedItemView.vue';
    import vueVersioning from '@imt/vue-versioning/src/mixins/versioning';
    import dayjs from 'dayjs';
    import isEqual from 'lodash/isEqual';
    import sortBy from 'lodash/sortBy';
    import {Multiselect} from 'vue-multiselect';
    import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';

    import {helpWording} from '@/lang';
    import permissionsMixin from '@/mixins/permissions';
    import removeItemConfirmationMixin from '@/mixins/remove-item-confirmation';
    import {dropdownUtils, generateId} from '@/utilities';

    import BRAction from './ExpressionBuilder/BRAction.vue';
    import BRExpressionBuilder from './ExpressionBuilder/BRExpressionBuilder.vue';

    let initialValues = () => {
        return {
            actions: [
                {
                    data: {},
                    errorMessage: '',
                    id: generateId(),
                    actionType: null,
                },
            ],
            canAccommodate: false,
            category: {},
            condition: {},
            description: '',
            effectiveDate: '',
            helpWordingText: '',
            internalNotes: '',
            pageId: null,
            pageOptions: [],
            pageType: null,
            underwritingFix: false,
            ruleType: 'cross_validation',
            taskId: null,
            title: '',
            transactionType: [],
            versionId: null,
        };
    };


    export default {
        name: 'BRAddEditForm',
        components: {
            BRAction,
            IMTUISelect,
            DatePicker,
            BRExpressionBuilder,
            Multiselect,
            PopoverSelect,
            ValidationError,
            VRelatedItemSelect,
            VRelatedItemView,
        },
        mixins: [
            authMixin,
            permissionsMixin,
            removeItemConfirmationMixin,
            toastsMixin,
            vueVersioning,
        ],
        props: {
            // Indicates if item is currently editable
            readOnly: {
                type: Boolean,
                default: false,
            },
            selectedRule: {
                type: Object,
                default: () => ({}),
            },
            selectedVersion: {
                type: Object,
                default: () => ({}),
            },
            // Contains an array for each group in the form. Each array contains the tooltips
            tooltipWording: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                categoryOptions: [
                    {
                        label: 'cross validation',
                        value: 'cross',
                    },
                    {
                        label: 'issue validation',
                        value: 'issue',
                    },
                ],
                conditionOptions: [
                    {
                        label: 'rule is met',
                        value: 'met',
                    },
                    {
                        label: 'rule is not met',
                        value: 'not_met',
                    },
                ],
                effectiveDatePicker: null,
                helpWording: helpWording.en,
                isEditing: false,
                isSaving: false,
                ...initialValues(),
            };
        },
        computed: {
            inMergeView() {
                return this.$route.name === 'merge';
            },
            isPageDisplayOrCleanUp() {
                return this.ruleType === 'page_display' || this.ruleType === 'clean_up';
            },
            orderedActions() {
                return sortBy(this.actions, a => parseInt(a.id));
            },
            pageSelectLabel: dropdownUtils.pageSelectLabel,
            pageSelectPlaceholder: dropdownUtils.pageSelectPlaceholder,
            pageTypes: dropdownUtils.pageTypes,
            ruleTypes: dropdownUtils.ruleTypes,
            showPageFields() {
                return this.ruleType === 'page_validation' || this.ruleType === 'page_display';
            },
            transactionTypes: dropdownUtils.transactionTypes,
            yesNoOptions: dropdownUtils.yesNoOptions,
            ...mapGetters('expressionBuilder', [
                'allDropDownsLoaded'
            ]),
            ...mapState([
                'currentPolicySystem'
            ]),
            ...mapState('vueVersioning', [
                'combinedVersion'
            ]),
        },
        watch: {
            pageId() {
                this.setLinkedPageData();
            },
            async pageType(newValue) {
                await this.$nextTick();
                await this.loadPageOptions(newValue);
            },
            ruleType(type) {
                if (type === 'clean_up') {
                    this.categoryOptions = [
                        {
                            label: 'page navigation',
                            value: 'navigation',
                        },
                    ];

                    this.category = {
                        label: 'page navigation',
                        value: 'navigation',
                    };
                } else if (type === 'page_display') {
                    this.categoryOptions = [
                        {
                            label: 'page display',
                            value: 'display',
                        },
                    ];

                    this.category = {
                        label: 'page display',
                        value: 'display',
                    };
                } else {
                    this.categoryOptions = [
                        {
                            label: 'cross validation',
                            value: 'cross',
                        },
                        {
                            label: 'issue validation',
                            value: 'issue',
                        },
                    ];

                    this.category = {
                        label: 'cross validation',
                        value: 'cross',
                    };
                }

                if (type === 'cross_validation') {
                    this.clearLinkedPageData();
                } else {
                    this.setLinkedPageData();
                }
            },
            showPageFields(newValue) {
                // TODO: this is temporary until we decide if we call fully remove the `pageType` field
                if (newValue) {
                    this.pageType = 'page';
                }
            },
        },
        mounted() {
            this.clearLinkedPageData();
            if (this.inMergeView) {
                this.initMergeVersion();
            } else if (this.selectedVersion?.id) {
                this.initExisting();
            } else {
                this.initNew();
            }
        },
        methods: {
            resetPageType() {
                if (!this.showPageFields) {
                    this.$refs.validationObserver.reset();
                    this.pageType = null;
                    this.pageId = null;
                }
            },
            addAction() {
                this.$ga.event('Add Edit Form', 'Add Action', 'action');
                this.actions.push({
                    data: {},
                    errorMessage: '',
                    id: generateId(),
                    actionType: '',
                });
            },
            clearLinkedPageData() {
                this.SET_EXPRESSION_BUILDER_DATA({
                    field: 'linkedPageData',
                    data: {
                        pageId: null,
                        pageType: null,
                        ruleType: null,
                    }
                });
            },
            finish() {
                this.isSaving = false;
                this.CLEAR_DROPDOWN_LOADING();
                this.initNew();
            },
            initExisting() {
                this.isEditing = true;
                this.actions = this.selectedVersion.actions;
                this.canAccommodate = this.selectedVersion.canAccommodate;
                this.category = dropdownUtils.findOptions(this.selectedVersion.category, this.categoryOptions)[0];
                this.condition = dropdownUtils.findOptions(this.selectedVersion.condition, this.conditionOptions)[0];
                this.transactionType = this.selectedVersion.transactionType || [];
                this.description = this.selectedVersion.description;
                this.effectiveDate = this.selectedRule.policyEffectiveDate;
                this.pageId = this.selectedRule.pageId;
                this.pageType = this.selectedRule.pageType;
                this.underwritingFix = this.selectedVersion.underwritingFix;
                this.ruleType = this.selectedRule.ruleType;
                this.versionId = this.selectedVersion.id;
                this.internalNotes = this.selectedVersion.internalNotes;
                this.helpWordingText = this.selectedVersion.helpWordingText;
                this.title = this.selectedRule.title;
                this.SET_EXPRESSION_GROUPS(this.selectedVersion.expressionGroups);
            },
            initMergeVersion() {
                this.isEditing = false;
                this.actions = this.combinedVersion.actions;
                this.category = dropdownUtils.findOptions(this.combinedVersion.category, this.categoryOptions)[0];
                this.condition = dropdownUtils.findOptions(this.combinedVersion.condition, this.conditionOptions)[0];
                this.transactionType = this.combinedVersion.transactionType || [];
                this.description = this.combinedVersion.description;
                this.effectiveDate = this.selectedRule.policyEffectiveDate;
                this.pageId = this.selectedRule.pageId;
                this.pageType = this.selectedRule.pageType;
                this.ruleType = this.selectedRule.ruleType;
                this.versionId = this.combinedVersion.id;
                this.internalNotes = this.combinedVersion.internalNotes;
                this.helpWordingText = this.combinedVersion.helpWordingText;
                this.title = this.selectedRule.title;
                this.SET_EXPRESSION_GROUPS(this.combinedVersion.expressionGroups);
            },
            initNew() {
                this.isEditing = false;

                Object.assign(this.$data, initialValues());

                this.category = dropdownUtils.findOptions('cross', this.categoryOptions)[0];
                this.condition = dropdownUtils.findOptions('met', this.conditionOptions)[0];

                if (this.$route.query.page_id && this.$route.query.page_type) {
                    this.ruleType = '';
                    this.pageId = this.$route.query.page_id;
                    this.pageType = this.$route.query.page_type;
                }

                let groupId = generateId(),
                    expressionId = generateId();

                this.SET_EXPRESSION_GROUPS([
                    {
                        id: groupId,
                        operator: null,
                        position: 0,
                        expressions: [
                            {
                                groupId,
                                id: expressionId,
                                operator: null,
                                position: 0,
                                components: [
                                    {
                                        children: [],
                                        expressionId,
                                        id: generateId(),
                                        data: {},
                                        position: 0,
                                        componentType: 'object',
                                    },
                                    {
                                        children: [],
                                        expressionId,
                                        id: generateId(),
                                        data: {comparisonType: 'string'},
                                        position: 1,
                                        componentType: 'condition',
                                    },
                                ],
                            },
                        ],
                    },
                ]);
            },
            async loadPageOptions(type) {
                if (this.$refs.pageSelectPopover) {
                    this.$refs.pageSelectPopover.isLoading = true;

                    let options = await this.fetchPageOptions(type);

                    this.pageOptions = options.map(option => {
                        const latestVersion = this.sortVersions(option)[0];

                        return {
                            label: latestVersion.title || option.title || `${option.number} ${latestVersion.screenName}`,
                            value: option.id,
                        };
                    });
                }
            },
            removeAction(action) {
                this.$ga.event('Add Edit Form', 'Remove Action', 'action');
                this.actions = this.actions.filter(a => !isEqual(a, action));
            },
            async save() {
                if (this.isSaving || !this.allDropDownsLoaded) {
                    return;
                }

                this.isSaving = true;
                this.$ga.event('Add Edit Form', 'Save', 'action');
                let valid = await this.$refs.validationObserver.validate();

                if (!this.canManage) {
                    this.isSaving = false;
                    this.error('You don\'t have permission to do that!');

                    return;
                } else if (!valid) {
                    this.isSaving = false;
                    this.$scrollTo('[aria-describedby*="bv_tooltip"]', 500, {offset: -60});

                    return;
                }

                let data = {
                        actions: this.$refs.actions?.map(a => a.compileForSave()),
                        canAccommodate: this.canAccommodate,
                        category: this.category.value,
                        condition: this.condition.value,
                        description: this.description.trim(),
                        expressionGroups: this.$refs.expressionBuilder.compileForSave(),
                        helpWordingText: this.helpWordingText,
                        internalNotes: this.internalNotes,
                        policySystemId: this.currentPolicySystem.id,
                        policySystemPrefix: this.currentPolicySystem.policyPrefix,
                        underwritingFix: this.underwritingFix,
                        ruleType: this.ruleType,
                        transactionType: this.transactionType,
                        type: 'Rule',
                    },
                    effectiveDate = this.effectiveDate;

                if (effectiveDate) {
                    data.policyEffectiveDate = dayjs(effectiveDate, 'MM-DD-YYYY').format('YYYY-MM-DD');
                }

                if (this.versionId) {
                    data.ruleId = this.$route.params.ruleId;
                    data.id = this.versionId;
                    data.type = 'Version';
                    delete data.policySystemId;
                    delete data.policyEffectiveDate;
                    delete data.ruleType;
                } else if (this.showPageFields) {
                    data.pageType = this.pageType;
                    data.pageId = this.pageId;
                }

                try {
                    if (this.versionId) {
                        await this.updateVersion({
                            versionData: data,
                            queryParams: this.$route.query
                        });
                    } else {
                        await this.createRule(data);
                    }

                    this.finish();
                    this.success('Rule saved!');

                    if (this.$route.params.ruleId) {
                        this.$router.push({name: 'admin.rules.options', params: {ruleId: this.$route.params.ruleId}, query: {...this.$route.query}});
                    } else {
                        this.$router.push({name: 'admin.rules.list', query: {...this.$route.query}});
                    }
                } catch (error) {
                    this.isSaving = false;
                    this.error('Failed to save rule!');
                    utils.console.log(error);
                }
            },
            setLinkedPageData() {
                this.SET_EXPRESSION_BUILDER_DATA({
                    field: 'linkedPageData',
                    data: {
                        pageId: this.pageId,
                        pageType: this.pageType,
                        ruleType: this.ruleType,
                    }
                });
            },
            showTestModal() {
                this.$router.push({name: 'admin.rules.builder.test', query: {...this.$route.query}});
            },
            ...mapActions([
                'createRule',
                'fetchPageOptions',
                'updateVersion',
            ]),
            ...mapMutations('expressionBuilder', [
                'CLEAR_DROPDOWN_LOADING',
                'SET_EXPRESSION_BUILDER_DATA',
                'SET_EXPRESSION_GROUPS',
            ]),
        },
    };
</script>

<style lang="sass">
    .dropdown-menu.show
        z-index: 9999
</style>

<style
    lang="sass"
    scoped
>
    nav.sticky-top.edit-navbar
        background-color: var(--kc-gray-400)
        position: sticky
        top: 65px !important

    .mode.dark
        nav.sticky-top.edit-navbar
            background-color: var(--kc-blue-800)

    .fade-enter-active, .fade-leave-active
        transition: opacity .5s
    .fade-enter, .fade-leave-to
        opacity: 0
</style>
